<template>
  <highcharts
    :options="chartOptions"
    ref="chart"
  />
</template>

<script>
import { Chart } from 'highcharts-vue';
import BaseChart from "@/mixins/Chart";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { mapGetters } from 'vuex'

HighchartsMore(Highcharts);

export default {
  name: "LineChartComponent",
  mixins: [BaseChart],
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          isFill: false,
          fillColor: "#ffffff",
          lineColor: "#0092f9",
          showXaxisLabel: false,
          showXgridLines: false,
          showYaxisLabel: false,
          showYgridLines: false,
          showYscaleLabel: false,
          showLegend: false,
        };
      },
    },
    chart: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      chartRef: null,
      dataCollection: {},
      chartOptions: {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          type: 'areaspline',
          height: this.height
        },
        legend: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          gridLineWidth: this.enabledXGridLine,
          allowDecimals: false,
          categories: this.getChartLabels,
          labels: {
            enabled: false
          },
        },
        yAxis: {
          gridLineWidth: this.enabledYGridLine,
          title: {
            text: this.chartTitle
          },
          labels: {
            enabled: false
          },
        },
        // tooltip: {
        //   pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
        // },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: true,          // Enable markers on the areaspline series
              radius: 6,              // Set the size of the markers
              lineColor: undefined,
              fillColor: '#FFFFFF',
              lineWidth: 4,           // Border width of the markers
              symbol: 'circle'        // Use circular markers
            },
            color: this.chartColor,
            fillOpacity: 0.5
          },
          area: {
            pointStart: 0,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: this.buildSeriesData,
      },
      chartStyles: {
        width: this.width,
        height: this.height,
      },
    };
  },
  computed: {
    ...mapGetters({
      dashboardFilters: 'reporting/filters'
    }),
    chartColor()  {
      return this.config.lineColor ? this.config.lineColor : undefined;
    },
    enableXAxisLabel () {
      return this.config.showXaxisLabel
    },
    enableYAxisLabel () {
      return this.config.showYaxisLabel
    },
    enabledXGridLine() {
      return this.config.showXgridLines ? 1 : 0;
    },
    enabledYGridLine() {
      return this.config.showYgridLines ? 1 : 0;
    },
    getChartLabels() {
      let currentFilter = this.dashboardFilters.start;
      let shouldSelect = this.reportData.find((reportData) => reportData.interval_name === currentFilter);
      if (!shouldSelect) {
        shouldSelect = this.reportData[0];
      }
      return shouldSelect.report_data.labels;
    },
  },
  mounted() {
    this.chartRef = this.$refs.chart.chart;
    this.updateChart();
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
    updateChart () {
      this.chartOptions = {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          type: 'areaspline',
          height: this.height
        },
        legend: {
          enabled: this.getCData.values.length > 1
        },
        title: {
          text: ''
        },
        xAxis: {
          gridLineWidth: this.enabledXGridLine,
          allowDecimals: false,
          categories: this.getChartLabels,
          labels: {
            enabled: this.enableXAxisLabel
          }
        },
        yAxis: {
          gridLineWidth: this.enabledYGridLine,
          title: {
            text: this.chartTitle
          },
          labels: {
            enabled: this.enableYAxisLabel
          }
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: true,          // Enable markers on the areaspline series
              radius: 6,              // Set the size of the markers
              lineColor: undefined,
              fillColor: '#FFFFFF',
              lineWidth: 4,           // Border width of the markers
              symbol: 'circle'        // Use circular markers
            },
            color: this.chartColor,
            fillOpacity: 0.5
          },
          area: {
            pointStart: 0,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        series: this.buildSeriesData,
      }
      this.chartRef.update(this.chartOptions);
    }
  },
  watch: {
    config: {
      handler: function (newVal, oldVal) {
        this.updateChart();
      },
      deep: true
    }
  }
};
</script>