<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    width: {
      type: String,
      default: "500px",
    },
    height: {
      type: String,
      default: "135px",
    },
    completedReport: {
      type: Object,
      default: () => {},
    },
    input: {
      type: Object,
      default: function () {
        return {};
      },
    },
    chart: {
      type: Object,
      default: function () {
        return {
          data_model_metric: 'Brands'
        };
      }
    },
    reportData: {
      type: Array,
      default: function () {
        return [
          {
          report_data:
            {
              labels: ["Jan '23", "Feb '23", "Mar '23"],
              names: ["Avg. Users"],
              values: [[12, 25, 14]]
            }
        }
        ];
      }
    }
  },
  data() {
    return {
      sampleDataCollection: {},
      completedReportCollection: {},
      chartStyles: {
        height: this.height,
        width: this.width,
      },
      chartColors: ['#649bff', '#e49728', '#f5e02d', '#7479db', '#313131', '#64972f', '#b72f2f']
    };
  },
  computed: {
    ...mapGetters({
      dashboardFilters: 'reporting/filters'
    }),
    ensuredCompletedReport() {
      return this.completedReport || { data: null, labels: null };
    },
    getCData() {
      let currentFilter = this.dashboardFilters.start;
      let shouldSelect = this.reportData.find((reportData) => reportData.interval_name === currentFilter);
      if (!shouldSelect) {
        shouldSelect = this.reportData[0];
      }
      return shouldSelect.report_data;
    },
    buildSeriesData() {
      let data = this.getCData
      if (data.values.length === 1) {
        return [{
          name: this.chartTitle,
          color: this.chartColor ?? undefined,
          data: this.getCData.values[0]
        }]
      }
      console.log('Data?', data);
      let seriesData = []
      data.values.forEach((item, i) => {
        seriesData.push({
          name: data.names[i],
          data: data.values[i]
        })
      })

      return seriesData;
    },
    preparedData() {
      return this.ensuredCompletedReport.prepared_data || { title: "" };
    },
    rawData() {
      return this.ensuredCompletedReport.raw_data || [];
    },
    title() {
      return this.preparedData.title;
    },
    realDataCollection() {
      return this.dataCollection || this.sampledDataCollection;
    },
    chartTitle () {
      return this.chart.config !== undefined ?
        this.chart.config.yAxisName
        : this.chart.data_model !== undefined
          ? this.chart.data_model.name
          : 'Avg. User';
    },
    getDataUnit() {
      return this.getCData.unit;
    }
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
    getPrepared(key, defaultValue) {
      return Object.get(this.preparedData, key) || defaultValue;
    },
    getRandomNumber(max) {
      return Math.floor(Math.random() * Math.floor(max || 10));
    },
    getChartData() {
      return this.reportData[0].report_data.values[0]
    },
    getChartSeriesName() {
      return this.chart && Object.keys(this.chart).includes('data_model') ? this.chart.data_model.name : 'Registrations';
    }
  },
  watch: {
    reportData: function (newVal, oldVal) {
      this.chartOptions.series = this.buildSeriesData
      this.chartOptions.yAxis.title.text = this.getChartSeriesName();
    },
  }
};
</script>